<template>
  <div class="columns is-vcentered">
    <div v-if="bar" class="column">
      <progress
        :class="progressClasses"
        class="progress"
        :value="value"
        max="100"
      />
    </div>
    <div v-if="number" class="column is-narrow">
      <p :class="numberClasses">{{ desirability }}</p>
    </div>
    <div v-if="text" class="column is-narrow">
      <p class="has-text-primary">{{ rating.text }} demand vehicle</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanionDesirability',
  data: () => ({ loader: 0 }),
  props: {
    desirability: {
      type: Number,
      required: true
    },
    bar: {
      type: Boolean,
      required: false,
      default: () => true
    },
    loadBar: {
      type: Boolean,
      required: false,
      default: () => false
    },
    number: {
      type: Boolean,
      required: false,
      default: () => true
    },
    text: {
      type: Boolean,
      required: false,
      default: () => false
    },
    colouredNumber: {
      type: Boolean,
      required: false,
      default: () => true
    },
    boldNumber: {
      type: Boolean,
      required: false,
      default: () => true
    },
    isSquare: {
      type: Boolean,
      required: false,
      default: () => false
    },
    size: {
      type: String,
      required: false,
      default: () => 'medium'
    }
  },
  async mounted() {
    if (this.loadBar) {
      this.loadValue()
    }
  },
  computed: {
    rating() {
      const { desirability } = this
      if (desirability < 33.33) return { colour: 'danger', text: 'Low' }
      else if (desirability < 66.66)
        return { colour: 'warning', text: 'Medium' }
      else return { colour: 'success', text: 'High' }
    },
    value() {
      return this.loadBar ? this.loader : this.desirability
    },
    numberClasses() {
      const boldness = this.boldNumber ? 'has-text-weight-bold' : ''
      const colour = this.colouredNumber ? `has-text-${this.rating.colour}` : ''
      return `${boldness} ${colour}`
    },
    progressClasses() {
      const colour = `is-${this.rating.colour}`
      const border = this.isSquare ? 'is-square' : ''
      const size = `is-${this.size}`
      return `${colour} ${border} ${size}`
    }
  },
  methods: {
    loadValue() {
      let interval = setInterval(() => {
        this.loader += 1
        if (this.loader >= this.desirability) clearInterval(interval)
      }, 10)
    }
  }
}
</script>

<style lang="sass" scoped>
.progress
  &.is-square
    border-radius: 6px
</style>
